<template>
  <div>
    <div :id=echartsId class="bar-echarts"
         :style="{'height':height+'px','width': width+'px'}"></div>
  </div>
</template>

<script>
export default {
  name: "BarEcharts",
  data() {
    return {
      width: document.body.clientWidth - 10,
      height: 400,
      myChart: null,
    }
  },
  props: {
    option: {type: Object},
    echartsId: String
  },
  mounted() {
    this.drawBar();
  },
  methods: {
    drawBar() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById(this.echartsId))
      // 绘制图表
      this.myChart.resize()
      this.myChart.setOption(this.option, true);
    }
  },
  watch: {
    'option': function () {
      this.drawBar()
    }
  }
}
</script>

<style scoped>
.bar-echarts{
  background-color: white;
}
</style>
