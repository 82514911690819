<template>
  <div class="customer-relation-panel"
       :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
    <van-row style="background-color: white">
      <van-col span="5" style="margin-top: 20px;padding-left: 10px">
        <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
           :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
        <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
           :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
      </van-col>
      <grp-fzr-card :data="this.columns" ref="grpFzrCard" @group="grp(arguments)"></grp-fzr-card>
      <van-col span="19">
        <van-search v-model="selectVal" :focus="theFocus" custom-class="inp" placeholder="请输入搜索关键词" show-action
                    @cancel="onCancel()" @search="init()"/>
      </van-col>
    </van-row>
    <van-row span="24">
      <div v-for="(item, index) in timeSpan" v-bind:key="item.id" :data="item">
        <van-col offset="1" span="5">
          <div class="timeSpan" @click="selectTime(item,index)" :value="date"
               :style="{color: item.use ? item.color : '#827E7E', background: item.use ? item.backColor: '#F3F3F3'}">
            {{ item.title }}
          </div>
        </van-col>
      </div>
    </van-row>
    <van-calendar v-model="show" position="top" type="range" @confirm="onConfirm"/>
    <track-card :data="data" :title="data.title"></track-card>
    <van-row class="areaRow">
      <van-grid :column-num="3">
        <van-grid-item>
          <span class="vicon">5</span>
          新增初期客户
        </van-grid-item>
        <van-grid-item>
          <span class="vicon">5</span>
          初期一>中期
        </van-grid-item>
        <van-grid-item>
          <span class="vicon">5</span>
          中期一>深入
        </van-grid-item>
      </van-grid>
    </van-row>
    <list-card :data="data.table"></list-card>
    <van-divider/>
    <van-row>
      <bar-echarts :option="this.goodsVolumeECharts" echartsId="e3"></bar-echarts>
    </van-row>

    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import TrackCard from "@/components/Card/TrackCard";
import ListCard from "@/components/Card/ListCard";
import GrpFzrCard from "@/components/Card/GrpFzrCard";
import BarEcharts from "@/components/Echarts/BarEcharts";
import TitleAnchor from "@/components/TitleAnchor.vue";
import CrmTabBar from "@/components/CrmTabBar";
import {Lazyload} from 'vant';

export default {
  name: "CustomerRelationPanel",
  components: {
    TrackCard, ListCard, BarEcharts, TitleAnchor, Lazyload, CrmTabBar, GrpFzrCard
  },
  data() {
    return {
      isAll: false,
      //列表高度
      freshHeight: 0,
      selectVal: '',
      theFocus: false,
      showPopup: false,
      date: '',
      show: false,
      goodsVolumeECharts: {
        backgroundColor: "#ffffff",
        legend: {
          type: 'scroll',
          right: 0,//图例位置
          //设置图例形状
          icon: "roundRect",
          itemWidth: 26,  // 设置宽度
          itemHeight: 10, // 设置高度
          //图例文字的样式
          textStyle: {
            color: '#666666',
            fontSize: 12
          },
          data: ["初期", "中期", "深入合作"],
        },
        grid: {
          top: 30,
          bottom: 20,
          right: 5
        },
        tooltip: {},
        xAxis: {
          data: ["一季度", "二季度", "三季度", "四季度"],
        },
        yAxis: {},
        series: [
          {
            name: "初期",
            type: 'bar',
            show: true,
            barWidth: 20,
            itemStyle: {
              color: '#44db33'
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              fontSize: 14
            },
            data: [23, 24, 18, 25, 27, 28, 25],
          },
          {
            name: "中期",
            type: 'bar',
            show: true,
            barWidth: 20,
            itemStyle: {
              color: '#3398DB'
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              fontSize: 14
            },
            data: [10, 11, 9, 17, 14, 13, 14],
          },
          {
            name: "深入合作",
            type: 'bar',
            show: true,
            barWidth: 20,
            itemStyle: {
              color: '#1f2da8'
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              fontSize: 14
            },
            data: [10, 11, 9, 17, 14, 13, 14],
          },
        ],
        title: [{
          text: '客户季度看板',
          left: '0%',
          top: '0%',
          textStyle: {
            color: '#000000',
            fontSize: 14
          }
        }],
      },
      goodsVolumeECharts1: {
        backgroundColor: "#ffffff",
        legend: {
          type: 'scroll',
          right: 0,//图例位置
          //设置图例形状
          icon: "roundRect",
          itemWidth: 26,  // 设置宽度
          itemHeight: 10, // 设置高度
          //图例文字的样式
          textStyle: {
            color: '#666666',
            fontSize: 12
          },
          data: ["初期", "中期", "深入合作"],
        },
        grid: {
          top: 30,
          bottom: 20,
          right: 5
        },
        tooltip: {},
        xAxis: {
          data: ["一季度1", "二季度1", "三季度1", "四季度1"],
        },
        yAxis: {},
        series: [
          {
            name: "初期",
            type: 'bar',
            show: true,
            barWidth: 20,
            itemStyle: {
              color: '#44db33'
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              fontSize: 14
            },
            data: [23, 24, 18, 25, 27, 28, 25],
          },
          {
            name: "中期",
            type: 'bar',
            show: true,
            barWidth: 20,
            itemStyle: {
              color: '#3398DB'
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              fontSize: 14
            },
            data: [10, 11, 9, 17, 14, 13, 14],
          },
          {
            name: "深入合作",
            type: 'bar',
            show: true,
            barWidth: 20,
            itemStyle: {
              color: '#1f2da8'
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              fontSize: 14
            },
            data: [10, 11, 9, 17, 14, 13, 14],
          },
        ],
        title: [{
          text: '客户季度看板',
          left: '0%',
          top: '0%',
          textStyle: {
            color: '#000000',
            fontSize: 14
          }
        }],
      },
      data: {
        title: '客户总数量',
        companyName: '',
        showCompanyName: false,
        companyList: [
          {
            index: 0,
            id: "厦门建发金属有限公司",
            text: "厦门建发金属有限公司"
          },
          {
            index: 1,
            id: "建发（广州）有限公司",
            text: "建发（广州）有限公司"
          },
          {
            index: 2,
            id: "建发（上海）有限公司",
            text: "建发（上海）有限公司"
          },
          {
            index: 3,
            id: "鞍钢股份有限公司",
            text: "鞍钢股份有限公司"
          },
        ],
        menus: [
          {
            num: 10,
            unit: '个',
            name: '初期'
          },
          {
            num: 4,
            unit: '个',
            name: '中期'
          },
          {
            num: 2,
            unit: '个',
            name: '深入合作'
          },
          {
            num: 16,
            unit: '个',
            name: '总数'
          }
        ],
        table: {
          column: 4,
          span: [9, 5, 5, 5],
          thead: ['客户名称', '走访频次(次)', '区域', '合作等级'],
          tbody: []
        },
        tableList: [
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
          {name: '鞍钢股份有限公司', frequency: "5", area: "辽宁省", level: '深入合作'},
        ]
      },
      //组办下拉列表数据源
      columns: [
        {
          values: '',
          id: 'R00311',
          className: 'column1',
          text: '燕钢项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17039',
              className: 'column1',
              text: '李鹏飞',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12005',
              className: 'column1',
              text: '李阳',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13117',
              className: 'column1',
              text: '王立永',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            },
          ]
        },
        {
          values: '',
          id: 'R00258',
          className: 'column2',
          defaultIndex: 0,
          text: '散-总经办',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '05001',
              className: 'column2',
              text: '赵永鹏',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '04008',
              className: 'column2',
              text: '张鸿凯',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00030',
          className: 'column3',
          defaultIndex: 0,
          text: '散-现场组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '12066',
              className: 'column3',
              text: '张强',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00318',
          className: 'column4',
          defaultIndex: 0,
          text: '钢铁华南项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '21196',
              className: 'column4',
              text: '余蜀东',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00202',
          className: 'column5',
          defaultIndex: 0,
          text: '钢铁东北组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18198',
              className: 'column5',
              text: '王泽民',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '17032',
              className: 'column5',
              text: '马乾博',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '16166',
              className: 'column5',
              text: '孙男',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13006',
              className: 'column5',
              text: '迟海祥',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '21014',
              className: 'column5',
              text: '陈东宝',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12007',
              className: 'column5',
              text: '马兆磊',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18061',
              className: 'column5',
              text: '白也',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00301',
          className: 'column6',
          defaultIndex: 0,
          text: '钢煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17223',
              className: 'column6',
              text: '高龙',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00302',
          className: 'column7',
          defaultIndex: 0,
          text: '电煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16086',
              className: 'column7',
              text: '王帅',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00080',
          className: 'column8',
          defaultIndex: 0,
          text: '船管租船组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16034',
              className: 'column8',
              text: '刘小恺',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00307',
          className: 'column9',
          defaultIndex: 0,
          text: '长协项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '13072',
              className: 'column9',
              text: '张航',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18215',
              className: 'column9',
              text: '翟成基',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00170',
          className: 'column10',
          defaultIndex: 0,
          text: '(散)粮食组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18005',
              className: 'column10',
              text: '许小庆',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '14018',
              className: 'column10',
              text: '王杨',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
      ],
      // 点击的是哪条记录
      timeSpan: [
        {
          title: '当年',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getFullYear(),
          use: false,
        },
        {
          title: '当季度',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
        {
          title: '当月',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getMonth() + 1,
          use: false,
        },
        {
          title: '自定义',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
      ],
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.init()
  },
  methods: {
    init() {
      this.data.table.tbody = []
      this.data.tableList.forEach((item) => {
        this.data.table.tbody.push([item.name, item.frequency, item.area, item.level])
      })
    },
    selectTime(item, index) {
      for (let i = 0; i < this.timeSpan.length; i++) {
        this.timeSpan[i].use = false;
      }
      item.use = true;
      if (index == 3) {
        console.log(index)
        this.show = true
      }
      this.goodsVolumeECharts = this.goodsVolumeECharts1
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)}-${this.formatDate(end)}`;
      console.log(this.date)
      for (let i = 0; i < this.timeSpan.length; i++) {
        this.timeSpan[i].use = false;
      }
    },
    confirm() {
      this.showPopup = false
    },
    showGrpFzr() {
      this.$refs.grpFzrCard.showPopup = true
      this.$refs.grpFzrCard.pickGrp = 'null'
      this.$refs.grpFzrCard.pickcFzr = 'null'
      this.showPopup = true
    },
    onCancel() {
      for (let i = 0; i < this.timeSpan.length; i++) {
        this.timeSpan[i].use = false;
      }
    },
    selectGroup(item, index) {
      for (let i = 0; i < this.columns.length; i++) {
        this.columns[i].use = false;
      }
      item.use = true;
    },
    selectFzr(item, index) {
      for (let i = 0; i < this.columns.length; i++) {
        for (let j = 0; j < this.columns[i].users.length; j++) {
          this.columns[i].users[j].use = false;
        }
      }
      item.use = true;
    },
    grp(msg) {
      // data为子页面的传过来的数据
      if (msg[0] != 'null') {
        console.log(msg[0])
      }
      if (msg[1] != 'null') {
        console.log(msg[1])
      }
    },
  }
}
</script>

<style scoped>
.customer-relation-panel {
  background: #FFFFFF;
  height: 100%;
}

.table-h-row .col {
  text-align: center;
}

.customer-relation-panel .timeSpan {
  padding-top: 5px;
  width: 70px;
  height: 30px;
  background-color: #F3F3F3;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  vertical-align: middle
}

.customer-relation-panel .areaRow {
  font-size: 15px
}

.customer-relation-panel .vicon {
  font-size: 50px;
  color: red
}
</style>
