<template>
    <div class="track-card">
        <div class="title-div">
          {{ title }}
        </div>

        <van-row class="num-items">
            <van-grid :column-num="4">
                <van-grid-item v-for="(item,index) in data.menus" :key="index">
                    <van-row class="num">
                        {{item.num}}
                    </van-row>
                </van-grid-item>

                <van-grid-item v-for="(item,index) in data.menus" :key="index+data.menus.length" class="name-row">
                    {{item.name}}
                </van-grid-item>
            </van-grid>
        </van-row>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Row, Col, Grid, GridItem} from 'vant'

    Vue.use(Row).use(Col).use(Grid).use(GridItem)

    export default {
        name: "TrackCard",
        components: {},
        props: {
            data: {type: Object},
            title: {type: String}
        },
        methods: {}
    }
</script>

<style scoped>

    .title-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 22px;
        padding-top: 10px;
    }

    .track-card {
        background: white;
        margin: 10px 10px;
        border-radius: 7px;
        box-shadow: rgba(85, 126, 198, 0.37) 0 0 6px 0;
        padding-bottom: 5px;
    }

    .track-card .num {
        font-size: 24px;
        color: red !important;
    }

    .track-card .text {
        font-size: 12px;
        padding: 1px 5px;
    }

    .track-card .num-items {
        margin-top: 10px;
    }
</style>
<style>
    .track-card .name-row div.van-grid-item__content {
        padding: 10px 2px;
        font-size: 10px;
    }
</style>
