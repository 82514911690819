<!--TitleAnchor.vue-->
<template>
  <div class="comTitle">
    <div class="rebox">
      <div class="t1"></div>
      <div class="t2"></div>
      <div class="outTitle">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style scoped>
.rebox{
  display:flex;
  flex-direction: column;
  justify-content: center;
  position: relative
}
.t1{
  width: 240px;
  height: 1px;
  background: #b8b2b2
}
.t2{
  width: 220px;
  height: 1px;
  background: #6b6b6b;
  margin-top: 6px;
  margin-left: 10px
}
.outTitle{
  width: 114px;
  font-size: 16px;
  font-weight: bold;
  background: white;
  padding: 6px;
  position: absolute;
  top: -14px;
  left: calc((100% - 114px)/2);
  text-align: center;
}
.comTitle{
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}
</style>
