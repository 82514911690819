<template>
    <div class="list-card">
        <van-row class="thead">
            <van-col :span="data.span[index]" v-for="(item,index) in data.thead" v-bind:key="index"
                     v-html="item" class="table-col"></van-col>
        </van-row>
        <van-row v-for="(item,index) in data.tbody" v-bind:key="index"
                 :class="index%2===0?'row-one':'row-two'">
            <van-col :span="data.span[i]" class="an-ellipsis table-col"
                     v-for="(it,i) in item"
                     v-bind:key="i" v-html="it">
            </van-col>
        </van-row>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Row, Col, Cell} from 'vant'

    Vue.use(Row).use(Col).use(Cell)
    export default {
        name: "ListCard",
        components: {},
        props: {
            data: {type: Object}
        },
    }
</script>

<style scoped>
    .list-card {
        margin: 2px 0px;
        padding: 0 5px;
        padding-bottom: 10px;
        background: #FFFFFF;
        border-radius: 7px;
    }

    .list-card .thead {
        font-size: 12px;
        color: white;
        background: #7DC8FF;
        padding: 10px 0;
    }

    .list-card .row-one {
        font-size: 12px;
        background: #EBF8FD;
        padding: 10px 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list-card .row-two {
        font-size: 12px;
        background: #FFFFFF;
        padding: 10px 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list-card .table-col {
        text-align: center;
    }

</style>
