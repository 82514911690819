import { render, staticRenderFns } from "./BarEcharts.vue?vue&type=template&id=fbf0b5b6&scoped=true"
import script from "./BarEcharts.vue?vue&type=script&lang=js"
export * from "./BarEcharts.vue?vue&type=script&lang=js"
import style0 from "./BarEcharts.vue?vue&type=style&index=0&id=fbf0b5b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbf0b5b6",
  null
  
)

export default component.exports